import { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"
import { CX_CORE_FEATURES_ENUM, featureCheck } from "@/common/util/featuresUtil"

const prepareRoutes: RouteRecordRaw[] = [
  {
    path: "/prepare",
    name: "prepare",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: `${ProtectedRoutes.prepare.models.path}/:id?`,
        name: ProtectedRoutes.prepare.models.pathName,
        component: () =>
          featureCheck(CX_CORE_FEATURES_ENUM.EDIT_MODELS)
            ? import("@/modules/prepare/pages/ModelsOverview.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Prepare",
              translationKey: "sideMenu.prepare"
            },
            {
              title: "Models Overview",
              translationKey: "sideMenu.model",
              routeName: ProtectedRoutes.prepare.models.pathName
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.prepare.mapping.path,
        name: ProtectedRoutes.prepare.mapping.pathName,
        component: () =>
          featureCheck(CX_CORE_FEATURES_ENUM.CROSS_MAPPINGS)
            ? import("@/modules/prepare/pages/MappingOverview.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Prepare",
              translationKey: "sideMenu.prepare"
            },
            {
              title: "Mapping",
              translationKey: "sideMenu.mapping",
              routeName: ProtectedRoutes.prepare.mapping.pathName
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.prepare.converter.path,
        name: ProtectedRoutes.prepare.converter.pathName,
        component: () =>
          featureCheck(CX_CORE_FEATURES_ENUM.CONVERTERS)
            ? import("@/modules/prepare/pages/ConvertersOverview.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Prepare",
              translationKey: "sideMenu.prepare"
            },
            {
              title: "Converters Overview",
              translationKey: "sideMenu.converter",
              routeName: ProtectedRoutes.prepare.converter.pathName
            }
          ]
        }
      }
    ]
  }
]

export default prepareRoutes
