import type { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"
import { DIGITAL_TWIN_FEATURES_ENUM, featureCheck } from "@/common/util/featuresUtil"

const consumerRoutes: RouteRecordRaw[] = [
  {
    path: "/consume",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.consume.offers.path,
        name: ProtectedRoutes.consume.offers.pathName,
        component: () => import("@/modules/consume/non-cx/search-offer/pages/SearchCatalog.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "Search data offer",
              translationKey: "sideMenu.searchDataOffers",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.consume.digitalTwin.path,
        name: ProtectedRoutes.consume.digitalTwin.pathName,
        component: () =>
          featureCheck(DIGITAL_TWIN_FEATURES_ENUM.BROWSE)
            ? import(
                "@/modules/consume/cx/digital-twin-browser/pages/DigitalTwinBrowserConsume.vue"
              )
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "Digital Twin Browser",
              translationKey: "sideMenu.digitalTwin",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default consumerRoutes
