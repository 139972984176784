import { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"

const governRoutes: RouteRecordRaw[] = [
  {
    path: "/govern",
    name: "govern",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.govern.policy.path,
        name: ProtectedRoutes.govern.policy.pathName,
        component: () => import("@/modules/govern/policies/pages/Policies.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Govern",
              translationKey: "sideMenu.govern",
              routeName: ""
            },
            {
              title: "My Policies",
              translationKey: "sideMenu.myPolicies",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.govern.subscribedAgreements.path,
        name: ProtectedRoutes.govern.subscribedAgreements.pathName,
        component: () =>
          import("@/modules/govern/subscribedAgreements/pages/SubscribedAgreements.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Govern",
              translationKey: "sideMenu.govern",
              routeName: ""
            },
            {
              title: "Subscribed data agreements",
              translationKey: "sideMenu.subscribedAgreements",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.govern.outgoingAgreements.path,
        name: ProtectedRoutes.govern.outgoingAgreements.pathName,
        component: () =>
          import("@/modules/govern/subscribedAgreements/pages/OutgoingAgreements.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Govern",
              translationKey: "sideMenu.govern",
              routeName: ""
            },
            {
              title: "Outgoing data agreements",
              translationKey: "sideMenu.outgoingAgreements",
              routeName: ""
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.govern.publicationTemplates.path,
        name: ProtectedRoutes.govern.publicationTemplates.pathName,
        component: () =>
          import("@/modules/govern/publicationTemplates/pages/PublicationTemplates.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Govern",
              translationKey: "sideMenu.govern",
              routeName: ""
            },
            {
              title: "Publication templates",
              translationKey: "sideMenu.publicationTemplates",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default governRoutes
