import type { RouteRecordRaw } from "vue-router"
import { ProtectedRoutes } from "../IRoute"

const applicationRoutes: RouteRecordRaw[] = [
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: ProtectedRoutes.applications.pcfExchange.path,
        name: ProtectedRoutes.applications.pcfExchange.pathName,
        component: () => import("@/modules/error/PageNotFound.vue"),
        // component: () => import("@/modules/applications/pcfExchange/pages/pcfExchange.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Applications",
              translationKey: "sideMenu.applications",
              routeName: ""
            },
            {
              title: "PCF Exchange",
              translationKey: "sideMenu.pcfExchange"
            }
          ]
        }
      },
      {
        path: ProtectedRoutes.applications.pcfRequests.path,
        name: ProtectedRoutes.applications.pcfRequests.pathName,
        component: () => import("@/modules/error/PageNotFound.vue"),
        // component: () => import("@/modules/applications/pcfRequests/pages/pcfRequests.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Applications",
              translationKey: "sideMenu.applications",
              routeName: ""
            },
            {
              title: "PCF Requests",
              translationKey: "sideMenu.pcfRequests"
            }
          ]
        }
      }
    ]
  }
]

export default applicationRoutes
